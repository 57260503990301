<template>
  <v-card>
    <v-expand-transition>
      <v-card-text v-if="loading.includes('players')">
        <v-card-text class="text-center">
          <h3>Please wait, while I do some looking up</h3>
          <v-progress-circular indeterminate v-bind:size="50" color="color3"></v-progress-circular>
        </v-card-text>
      </v-card-text>
      <v-list two-line v-else>
        <template v-for="(player, i) in displayRoster">
          <v-expand-transition :key="`item-${i}`">
            <v-list-item>
              <v-list-item-avatar tile class="my-0" height="64" width="64">
                <v-img :src="player.profilePic"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{player.fullname}} {{player.jersey ? `#${player.jersey}` : ''}}
                  <v-badge inline color="primary" v-if="team.isCaptain(player.id)" class="ma-0">
                    <span slot="badge">C</span>
                  </v-badge>
                </v-list-item-title>
                <v-list-item-subtitle v-if="player.showMetrics(view, user)">
                  {{player.metricsArray | pipeArray}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <span>View Profile</span>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="color3"
                      icon small
                      v-on="on"
                      :to="myRoute(player)"
                    >
                      <v-icon>fas fa-portrait</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action v-if="access.admin || access.roster">
                <v-menu close-on-click offset-x left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="grey"
                      icon small
                      v-on="on"
                      :loading="loading.includes(player.id)"
                    >
                      <v-icon>fas fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="onCaptainClick(player.id)">{{ team.isCaptain(player.id) ? 'Remove' : 'Assign' }} Captain</v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-expand-transition>
          <v-divider :key="`divider-${i}`" v-if="i < displayRoster.length - 1"></v-divider>
        </template>
      </v-list>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { difference } from '@/helpers/SetFunctions'
import PlayerProfile from '@/classes/PlayerProfile'
import { firstBy } from 'thenby'

export default {
  props: ['team', 'active', 'access'],
  data () {
    return {
      loading: [],
      displayRoster: [],
      rawRoster: []
    }
  },
  computed: {
    ...mapGetters(['view', 'user']),
    fullyLoaded () {
      if (this.team.players.length === 0) return true
      const loaded = new Set(this.displayRoster.map(m => m.id))
      const teamPlayers = new Set(this.team.players.map(m => m.playerProfileId))
      const diff = difference(loaded, teamPlayers)
      const diff2 = difference(teamPlayers, loaded)
      return diff.size === 0 && diff2.size === 0
    }
  },
  methods: {
    getPlayers () {
      if (this.fullyLoaded) return
      this.loading.push('players')
      this.$VBL.get.playerList(null, { ids: this.team.players.map(m => m.playerProfileId) })
        .then(r => {
          this.rawRoster = [...r.data.players]
          this.displayRoster = r.data.players.map(m => new PlayerProfile(null, m))
          this.displayRoster.sort(firstBy('lastName').thenBy('firstName'))
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = this.loading.filter(f => f !== 'players') })
    },
    myRoute (player) {
      const mem = !!player.memberId && this.theme.membership
      return {
        name: mem ? 'member-profile' : 'player-profile',
        params: {
          id: mem ? player.memberId : player.id,
          playerProfileId: player.id,
          memberId: player.memberId,
          name: player.name,
          profilePic: player.profilePic,
          male: player.male
        }
      }
    },
    onCaptainClick (id) {
      const tag = `captain-${id}`
      const iTags = [...this.team.tags].filter(f => !f.startsWith('captain-'))
      iTags.push(tag)
      // let iTags = [...this.team.tags]
      // if (iTags.includes(tag)) {
      //   iTags = iTags.filter(f => f !== tag)
      // } else {
      //   iTags.push(tag)
      // }
      this.loading.push(id)
      this.$VBL.team.tags.post(this.team.id, iTags)
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = this.loading.filter(f => f !== id) })
    }
  },
  watch: {
    'team.players.length': 'getPlayers',
    active: function (v) {
      if (v) {
        this.getPlayers()
      }
    }
  },
  created () {
    this.getPlayers()
  }
}
</script>
